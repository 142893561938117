<template>
	<div class="project-revenue-dashboard card-shadow">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Revenue</div>
			<v-spacer></v-spacer>
			<v-select
				class="cm-menu-select elevation-0 red--text"
				v-model="project_overview"
				:items="projectFilterList"
				hide-details
				dense
				left
				solo
			>
				<template v-slot:selection="{ item }">
					<span class="blue--text font-level-3-bold">{{ item }}</span>
				</template>
			</v-select>
		</v-layout>
		<div class="mx-auto px-4 py-3">
			<div>
				<apexchart
					type="bar"
					height="450"
					:options="chartOptions"
					:series="series"
					width="100%"
				></apexchart>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ProjectStatus",
	data() {
		return {
			project_overview: "All Time",
			projectFilterList: ["All Time", "This Week", "Last Week", "This Month", "Last Month"],
			series: [
				{
					name: "Orders",
					data: [23, 11, 42, 27, 43, 22, 37, 21, 44, 22, 30, 12],
				},
				{
					name: "Earning",
					data: [44, 55, 41, 67, 62, 43, 41, 71, 56, 47, 43, 33],
				},
				{
					name: "Refunds",
					data: [30, 25, 36, 30, 23, 35, 34, 52, 29, 26, 29, 44],
				},
			],
			chartOptions: {
				chart: {
					type: "bar",
					height: 350,
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: "55%",
						endingShape: "rounded",
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					show: true,
					width: 2,
					colors: ["transparent"],
				},
				labels: [
					"01/01/2003",
					"02/01/2003",
					"03/01/2003",
					"04/01/2003",
					"05/01/2003",
					"06/01/2003",
					"07/01/2003",
					"08/01/2003",
					"09/01/2003",
					"10/01/2003",
					"11/01/2003",
				],
				markers: {
					size: 0,
				},
				xaxis: {
					categories: [
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
						"Jan",
					],
				},
				tooltip: {
					shared: true,
					intersect: false,
					y: {
						formatter: function (y) {
							if (typeof y !== "undefined") {
								return y.toFixed(0) + " points";
							}
							return y;
						},
					},
				},
			},
		};
	},
};
</script>
