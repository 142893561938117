<template>
	<div class="total-users-dashboard card-shadow">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Total Users</div>
			<v-spacer></v-spacer>
		</v-layout>
		<div class="mx-auto px-4 py-3" style="max-width: 400px">
			<div>
				<apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
			</div>
			<ul class="ul statics d-flex pa-0 mt-5 py-5">
				<li class="li text-center">
					<div class="color747474">Target</div>
					<div class="mt-1">
						<v-icon size="28px" color="red">mdi-arrow-down</v-icon>
						<span class="number red--text">25 K</span>
					</div>
				</li>
				<li class="li text-center">
					<div class="color747474">Last Week</div>
					<div class="mt-1">
						<v-icon size="28px" color="green">mdi-arrow-up</v-icon>
						<span class="number green--text">25 K</span>
					</div>
				</li>
				<li class="li text-center">
					<div class="color747474">Last Month</div>
					<div class="mt-1">
						<v-icon size="28px" color="blue">mdi-arrow-up </v-icon>
						<span class="number blue--text">25 K</span>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	name: "Total-users",
	data() {
		return {
			series: [25, 15, 44, 55],
			chartOptions: {
				chart: {
					width: "100%",
					type: "pie",
				},
				labels: ["Monday", "Tuesday", "Wednesday", "Thursday"],
				theme: {
					monochrome: {
						enabled: false,
					},
				},
				plotOptions: {
					pie: {
						dataLabels: {
							offset: -5,
						},
					},
				},
				legend: {
					show: false,
				},
			},
		};
	},
};
</script>
