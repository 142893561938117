<template>
	<div class="team-preview">
		<div class="team-wrapper card-shadow">
			<div class="team-fields pb-5">
				<div class="team-grp">
					<div
						class="team_member d-flex justify-content-between align-items-center p-5"
						style="border-bottom: 1px solid #e8dada"
					>
						<div class="font-team">
							My Activity
							<p class="mb-0 fw-500">890,344 Sales</p>
						</div>
						<div class="">
							<v-icon>mdi-dots-horizontal</v-icon>
						</div>
					</div>
					<table id="table" class="table" width="100">
						<tbody>
							<tr>
								<td>
									<div class="">
										<p class="fw-500 mb-0"><b>08:42</b></p>
									</div>
								</td>
								<td class="progress-line">
									<div class="circle-line blue"></div>
									<p class="fw-500 mb-0">Outlines keep youe honest and keep structure</p>
								</td>
							</tr>
							<tr>
								<td>
									<div class="">
										<p class="mb-0"><b>18:42</b></p>
									</div>
								</td>
								<td class="progress-line">
									<div class="circle-line cyan"></div>
									<p class="fw-500 mb-0 font-size-18">Outlines keep youe honest and keep structure</p>
								</td>
							</tr>
							<tr>
								<td>
									<div class="">
										<p class="mb-0"><b>03:42</b></p>
									</div>
								</td>
								<td class="progress-line">
									<div class="circle-line indigo"></div>
									<p class="fw-500 mb-0 font-size-18">Outlines keep youe honest and keep structure</p>
								</td>
							</tr>
							<tr>
								<td>
									<div class="">
										<p class="mb-0"><b>12:42</b></p>
									</div>
								</td>
								<td class="progress-line">
									<div class="circle-line orange"></div>
									<p class="fw-500 mb-0 font-size-18">Outlines keep youe honest and keep structure</p>
								</td>
							</tr>
							<tr>
								<td>
									<div class="">
										<p class="mb-0"><b>14:02</b></p>
									</div>
								</td>
								<td class="progress-line">
									<div class="circle-line yellow"></div>
									<p class="fw-500 mb-0 font-size-18">Outlines keep youe honest and keep structure</p>
								</td>
							</tr>
							<tr>
								<td>
									<div class="">
										<p class="mb-0"><b>22:12</b></p>
									</div>
								</td>
								<td class="progress-line">
									<div class="circle-line blue"></div>
									<p class="fw-500 mb-0 font-size-18">Outlines keep youe honest and keep structure</p>
								</td>
							</tr>
							<tr>
								<td>
									<div class="">
										<p class="mb-0"><b>16:32</b></p>
									</div>
								</td>
								<td class="progress-line">
									<div class="circle-line success"></div>
									<p class="fw-500 mb-0 font-size-18">Outlines keep youe honest and keep structure</p>
								</td>
							</tr>
							<tr>
								<td>
									<div class="">
										<p class="mb-0"><b>06:52</b></p>
									</div>
								</td>
								<td class="progress-line">
									<div class="circle-line red"></div>
									<p class="fw-500 mb-0 font-size-18">Outlines keep youe honest and keep structure</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MyActivity",
};
</script>
